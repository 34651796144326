@import "./constants/theme.scss";

.slide-in {
  animation: slidein .2s forwards;
}

.slide-out {
  animation: slideout .2s forwards;
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translatey(-50px);
  }

  to {
    opacity: 1;
    transform: translatey(0px);
  }
}

@keyframes slideout {
  from {
    opacity: 1;
    transform: translatey(0px);
  }

  to {
    opacity: 0;
    transform: translatey(-50px);
  }
}
.toastContainer{
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  max-width: 400px;
  min-height: 60px;
  display: flex;
  position: absolute;
  z-index: 9999999;
  background-color: white;
  border-radius: 5px;
  .custom-toast-body{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    margin-left: 10px;
    text-align: left;
    align-items: flex-start;
    justify-content: center;
    margin-right: 10px;
  }
  .custom-toast-header{
    width: 60px;
    // background: green;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    svg{
      color: white;
      margin: 10px;
      height: 60px;
    }
  }
}
