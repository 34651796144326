@import "./constants/theme.scss";

.auth_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .auth_logo {
    margin-top: 30px;
    margin-bottom: 50px;
    img {
      width: 90%
    }
  }

  .auth_header{
    font-size: 24px;
    color: $first;
  }

  .card {
    width: 500px;

    @media(max-width: 540px) {
      width: 90%;
    }
  }

  .link {
    width: 100%;
    text-align: left !important;
    font-size: 16px;
  }
}

.auth_button {
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.errorContainer {
  border-radius: 5px;
  background-color: $third;
  justify-items: center;

  * {
    margin: 0;
    padding: 10px;
    color: $white
  }

  .btn-custom_primary{
    border: 1px solid red;
  }
}