@import './constants/theme.scss';

.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .coverPhotoContainer {
    position: relative;
    margin-bottom: 70px;
    width: 100%;
    .coverPhoto {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    .coverPhotoOverlay{
      width: 100%;
      height: 100%;
      
      position: absolute;
      background-color: rgba(0,0,0,.5);
  
      .coverPhotoContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: 35px;  
        font-weight: 300;
        text-shadow: 1px 1px $text-shadow;
        width: 90%;

        button {
          font-size: 25px;
        }
  
        h2 {
          color: $white;
        }
  
        h5 {
          color: $light-gray;
          font-style: italic;
        }
      }
    }
  }

  .col-section {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    h1 {
      color: $first;
      font-weight: 400;
    }
    .section-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 75px;
      svg {
        margin-left: 10px;
        color: $first;
        height: 70%;
        transition: .25s;
        &:hover{
          color: $second;
        }
      }
    }
    .linkInput{
      height: 60px;
      width: 20px;

      .linkText {
        color: $first;
        border-radius: 20px;
        background-color: transparent;
        border-right: transparent;
      }
      .textInputControl{
        border-radius: 20px;
        border-left: transparent;
        margin-left: -10px !important;
        padding-left: 0;
      }
    }
  }

  .section-whole{
    margin-bottom: 200px;
    h1{
      color: $first;
    }
    h5 {
      text-align: center !important;
      font-weight: 400;
      strong{
        color: $first
      }
    }
    button {
      margin-top: 20px;
      font-size: 25px;
    }
  }

  .section {
    width: 80%;
    display: flex;
    flex-direction:row;
    margin-top: 20px;
    justify-content: space-between;
    margin-bottom: 200px;
    &.whole{
      width: 100%;
    }
    &.reg {
      @media(max-width: 500px){
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        margin-bottom: 100px;
        .sectionInfo {
          margin-top: 50px;
        }
      }
    }
    &.rev {
      @media(max-width: 500px){
        width: 100%;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-content: center;
        margin-bottom: 100px;

        .sectionInfo {
          margin-top: 50px;
        }
      }
    }
    h1{
      text-align: center !important;
      color: $first;
      font-weight: 400;
    }
    h5 {
      text-align: center !important;
      font-weight: 400;
    }

    img{
      width: 30%;
      -webkit-filter: drop-shadow(5px 5px 5px #666666);
      filter: drop-shadow(5px 5px 5px #666666);

      @media(max-width: 500px){
        width: 50%;
      } 

    }

    .floating{
      animation-name: floating;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      margin-top: 5px;
    }

    .floating-spin{
      animation-name: floatSpinning;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      margin-top: 5px;
      margin-left: 20px;
    }

    .floating-scale{
      animation-name: floatScale;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      // margin-top: 5px;
      // margin-left: 20px;
    }
    
    .bigger_image{
      width: 40% !important;
    }

    .sectionInfo{
      width: 50%;
      text-align: left;
      justify-content:flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

@keyframes floatSpinning {
  0% { transform: rotate(-3deg);}
  50%  { transform: rotate(3deg);}
  100%   { transform: rotate(-3deg);}
}


@keyframes floatScale {
  0% { transform: scale(1);}
  50%  { transform: scale(.95);}
  100%   { transform: scale(1);}
}