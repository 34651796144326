@import './constants/theme.scss';

.uploadingText {
  margin-bottom: 10px;
}
.reportModal{
  .modal-body{
    min-height: 100px;
  }
  .SubmittedReport{
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $first;
    align-items: center;
    
    h3{
      margin-top: 10px;
    }
    
    svg {
      height: 100px;
      color: green
    }
  }

  .reportSpinner{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .spinner-border{
      height: 100px;
      width: 100px;
      border: 0.5em solid $first;
      border-right-color: transparent;
    }
  }
  .buttonContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  ul{
    list-style: none;
    padding-left: 0 !important;
    li{
      padding-top: 10px;
      input {
        margin-right: 5px;
      }
    }
  }
}
.profileScreenContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .profileContentsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    @media (min-width: 768px) {
      width: 75%;
    }

    .profilePageContentContainer{
      transform: translateY(-30px);
      display: flex;
      z-index: 1;
      flex-direction: column;
    }
    
    .profileButtonContainer{
      position: relative;
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media (max-width: 768px) {
        width: 90%;  
      }
    }
  }

  .profilePictureContainer {
    margin-top: -100px;
    // z-index: 9;
    position: relative;
    
    .profilePicture { 
      border-radius: 100%;
      width: 150px;
      height: 150px;
      object-fit: cover;
      box-shadow: 0 4px 8px 0 $box-shadow, 0 6px 20px 0 $box-shadow;
    }

    .skel_proPic {
      height: 150px;
      position: absolute;
      width: 100%;
      span{
        border-radius: 100%;
        height: 100%;
      }
    }
  }

  .coverPhotoContainer {
    width: 100%;
    height: 150px;
    transition: opacity 1s ease;

    
    .reportContainer{
      position: absolute;
      border: 2px solid $third;
      width: 35px;
      height: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 5px;
      right: 10px;
      margin-top: 10px;
      svg {
        height: 20px;
        color: $third;
      }
    }

    .skel_cover {
      height: 150px;
      position: absolute;
      width: 100%;

      span{
        height: 100%;
      }
    }

    .solidCoverPhoto {
      background-color: $first;
      width: 100%;
      height: 100%; 
    }

    .coverPhoto {
      width: 100%;
      height:100%;
      object-fit:cover; /* This can be one of the 5 values from above */
    }
  }

  .viewPublicProfile {
    top: 220px;
    left: 25px;

    .shareContainer{

      .file-input {
        width: 200px;
      }

      .file-input label{
        padding: 10px;
        width: 100%;
        cursor: pointer;
        transition: .25s;

        &:hover{
          background-color: $fourth;
          color: $white;
        }
  
        &:active{
          background-color: $fourth;
          color: $white;
        }
      }
    }
  }
  
  .editProfileContainer {
    top: 220px;
    right: 25px;


    .dropdown-item {
      padding: 0;
    }
    .file {
      opacity: 0;
      width: 0.1px;
      height: 0.1px;
      position: absolute;
    }

    .file-input {
      width: 200px;
    }

    .file-input label {
      padding: 10px;
      width: 100%;
      cursor: pointer;
      transition: .25s;

      &:hover {
        background-color: $fourth;
        color: $white;
      }
      &:active {
        background-color: $fourth;
        color: $white;
      }
    }
  }
  .nameContainer{
    margin-top: 5px;
    display: flex;
    flex-direction: column;

    .realNameContainer {
      font-size: 18px; 
      font-weight: bold;
    }
    .usernameContainer {
      font-size: 24px;
      font-weight: bold;
      color: $first;
    }
  }

  .socialMediaContainer {
    .socialMediaListContainer {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      * {
        height: 100%;
        padding-left: 5px;
        padding-right: 5px;
      }
      a {
        cursor: pointer;
        .socialMediaListIcon{
          color: $third;
          transition: .25s;
          cursor: pointer;

          &:hover {
            color: $fourth;
          }
        }
      }
    }
  }

  .addButtonContainer {
    padding: 10px;
    color: $white;
    border-radius: .25rem;
    background-color: $first;
    border: 1px solid $fourth;
    cursor: pointer;
    transition: .25s;
    margin-bottom: 5px;

    &:hover {
      background-color: $fourth;
    }
  }

  .infoContainer {
    flex-direction: column;
    width: 100%;
    align-items: center;
    align-self: center;
    display: flex;
    
    @media(min-width: 768px) {
      width: 50%;
    }

    .linkListContainer{
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-top: 10px;

      .twoRowAddButtonContainer {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        justify-content: space-between;


        .addButtonContainer {
          width: 49%
        }
      }

      .addSection{ 
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;

        .dropdownButton {
          button{
            width: 50%
          }
        }
        .buttonContainer{
          display: flex;
          flex-direction: row;
          .inlinepopupContainer {
            height: 20px;
            width: 20px;
          }
          
          button {
            margin-right: 10px;
          }
        }
      }

      .card-footer {
        width: 100%;

        .btn{
          margin: 5px;
        }
      }

      .editIcon {
        margin-left: auto;
        text-align: center;
        z-index: auto;
        &.sectionIconContainer{
          transform: translateY(5px);
          z-index: 99999;
          margin-left: 5px;
        }
        
        button::after {
          display: none;
        }

        .deleteText {
          color: $third !important;
        }
        .btn-outline-custom {
          border: 1px solid $first;

          .sectionIcon {
            color: $first;
          }
        }

        .btn-outline-none {
          .sectionIcon {
            transform: translateY(.2em);
            color: $first;
            transition: .25s;

            &:hover {
              color: $fourth;
            }
          }
        }
        .subSectionIcon {
          transform: translateX(5px);
          transform: translateY(-5px);
          color: $gray;
        }

        .linkIcon {
          color: $gray;
          transform: translateX(-10px);
        }
      }

      .linkContainerText {
        display: flex;
        height: 100%;
        align-items: center;

        .sectionTitle {
          font-size: 25px;
          font-weight: 500;
          color: $first;
          margin-bottom: -5px;
          margin-top: 10px;
          margin-left: 4px;
        }
      }

      .linkContainer {
        margin-top: 5px;
        margin-bottom: 5px;
        z-index: 1;

        &.border_primary {
          border: 2px solid $first;
          border-radius: 5px;
          padding-left: 15px;

          .linkContainerText{
            min-height: 60px;

            .linkText {
              font-size: 20px !important;
              font-weight: 400;
            }
          }
        }

        .border-light {
          border: 1px solid black !important;
        }

        .buttonContainer {
          display: flex;

          button {
            margin-right: 10px;
          }
        }

        a {
          color: inherit;
          text-decoration: none;
          cursor: pointer;
        }

        a:hover {
          color:$first; 
          text-decoration:none; 
          cursor:pointer;  
        }
      }
      .allSectionContainer{
        margin-bottom: 20px;
        .sectionContainer{
          .EditSectionContainer {
            margin-bottom: 10px;
            width: 100%;
  
            .buttonContainer {
              margin-bottom: 10px;
  
              button {
                margin-right: 10px;
              }
            }
          }
      }

        .sectionBodyContainer {
          padding: 5px;
  
          .sectionAddEditContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 5px;
            flex-direction: column;

            .addButtonContainer {
              width: 100%;
            }
          }
        }

        .subSectionContainer {
          margin-top: 10px;

          .card-body {
            padding: 10px 15px;
          }

          .subSectionHeader{
            color: $first;
          }
        }

        .subSectionInfo{
          margin-bottom: 10px;
          
          a{
            text-decoration: none;
          }

          .sectionInfoContainer {
            display: flex;
            flex-direction: column;
            margin-top: -10px;

            .linkButton {
              color: $fourth;
              text-decoration: underline;
              a {
                cursor: pointer;
              }
              svg {
                padding-right: 5px;
              }
            }
          }
        }
      }

      .sectionInfoContainer {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.editLinkContainer{
  margin-right: 15px;
  margin-bottom: 15px;
}

.editSocialMediaLinkContainer{
  margin-bottom: 30px;
  background-color: $lightish-gray;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}


.loadingContainer {
  margin-top: 100px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .spinner-border {
    height: 100px;
    width: 100px;
    border-width: 10px;
    border-color: $first;
    border-bottom-color: transparent;
  }

  .loadingText{
    margin-top: 10px;
    font-weight: 600;
    color: $first;
    font-size: 20px;
  }
}

.notFoundContainerProfile{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: $first;
    font-size: 32px;
  }

  .notFoundContent {
    font-size: 24px;
  }

  button{
    margin-top: 20px;
    width: 100px;
  }
}

.modal{
  z-index: 99999999 !important;
}