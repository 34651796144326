@import "../styles/constants/theme.scss";

.notFoundContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;

  .gradient-text {
    height: 180px;
    font-weight: bold;
    font-size: 140px;
    background-color: #CA4246;
    background-image: linear-gradient(
          45deg,
          $fifth 16.666%, 
          $first 16.666%, 
          $first 33.333%, 
          $second 33.333%, 
          $second 50%, 
          $third 50%, 
          $third 66.666%, 
          $fourth 66.666%, 
          $fourth 83.333%, 
          $fifth 83.333%
    );
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    animation: rainbow-text-simple-animation-rev 0.75s ease forwards;
  }

  .content {
    color: $first;
    font-weight: 500;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    @media (min-width: 768px) {
      width: 600px;
    }
  }

  .buttonContainer{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    button {
      width: 200px;
      margin: 10px;
      font-size: 18px;
      font-weight: 500;
    }

    .btn-outline-first {
      color: $first;
      border-color: $first;
      transition: .5s;

      &:hover {
        background-color: $first !important;
        border-color: $first;
        color: $white
      }
    }
  }
}

// ANIMATIONS

@keyframes rainbow-text-simple-animation-rev {
  0% {
      background-size: 650%;
  }
  40% {
      background-size: 650%;
  }
  100% {
      background-size: 100%;
  }
}
