@import "./constants/theme.scss";

.form {
  .descContainer{
    margin-bottom: 20px;
    margin-top: 10px;
    .desc{
      margin-top: 10px;
    }
  }
}

.check{
  color: $first;
  height: 100px;
  margin-top: 20px;
}

.submitted{
  margin-top: 10px;
}