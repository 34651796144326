@import './constants/theme.scss';

.profileSettingsScreenContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 100px;
  align-items: center;


  @media(min-width: 768px) {
    left: 50%;
    width: 90%;
    flex-direction: row;

    .profileInformationContainer {
      background-color: $light-gray;
      border-radius: 5px;
      margin-top: 55px;
    }
  }

  .profileInformationContainer{
    padding: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .profilePictureContainer {
      z-index: 9;
      position: relative;
  
      .profilePicture{
        border-radius: 100%;
        width: 150px;
        height: 150px;
        object-fit: cover;
        box-shadow: 0 4px 8px 0 $box-shadow, 0 6px 20px 0 $box-shadow;
        margin-bottom: 10px;
      }
    }

    .userName {
      font-size: 24px;
      font-weight: bold;
      color: $first;
    }
  
    .name {
      font-size: 18px; 
      font-weight: bold;
    }
  }

  .toggleFieldContainer{
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    background-color: $light-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    text-align: left;

    .textContainer{
      width: 82%;
      margin-right: 10px;
    }

    .checkmarkContainer{
      display: flex;
      align-items: center;
    }

    .textInputContainer{
      display: flex;
      align-items: center;

      .formContainer{
        display: flex;
        flex-direction: row;
        button {
          margin-left: 10px;
          font-weight: 800;
        }
      }
    }
  }

  .textFieldContainer {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
    background-color: $light-gray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
    padding-bottom: 10px;
    text-align: left;
    padding-top: 10px;
    height: 100px;

    @media(max-width: 300px) {
      .textContainer{
        margin-bottom: 5px;
        font-size: 20px;
      }
      flex-direction: column;
    }

    .checkmarkContainer{
      display: flex;
      align-items: center;
    }

    .textInputContainer{
      display: flex;
      align-items: center;

      .textInput {
        width: 150px;
      
        &.errorMessage{
          text-align: center;
        }
        @media(min-width: 400px) {
          width: 200px;
        }
      }

      .formContainer{
        display: flex;
        flex-direction: row;

        button {
          margin-left: 10px;
          font-weight: 800;
          height: 37px;
        }
      }
    }
  }

  .redButtonContainer {
    background-color: $light-gray;
    padding: 10px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    .note {
      margin-left: 10px;
      align-self: center;
      color: $gray;
    }
  }

  .settingsTitle{
    font-size: 30px;
    color: $first;
    margin-bottom: 10px;
  }
}
