@import "./styles/constants/theme.scss";

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  height: 100%;
}


.content-container {
  min-height: calc(100vh - 154px);
}

.first-bg {
  background-color: $first !important;
}

.first-bg-hover {
  background-color: $first !important;
  transition: .5s;
  &:hover {
    background-color: $fourth !important;
  }
}

.white-text {
  color: $white !important;
}

.popupModal{
  z-index: 99999999 !important;
  position: fixed !important;
}