// Color Pallette
$first: #3B5999;
$second: #00ACED;
$third: #D44332;
$third-hover: #992f23; 
$fourth: #36465D;
$fifth: #0080B2;

// Grays
$gray: #646464; 
$gray-border: #dadada;
$light-gray: #f1f1f1;
$lightish-gray: #eae9e9;
$medium-gray: #b3b3b3;

// Whites
$white: white;
$off-white: #cfcfcf;

// Blacks
$black-text: black;
$dark-gray-text: #383838;

// Shadows
$box-shadow: rgba(0, 0, 0, 0.2);
$text-shadow: black;