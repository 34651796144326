@import './constants/theme.scss';

.logoutContainer {
  color: $third !important;
}

.brand_container{
  .navbar_logo{
    height: 20px;
    margin-right: 5px;
    margin-top: 0;
    margin-bottom: 4px;
  }
  span{
    position: absolute;
    transform: translateY(2px);
    font-size: 18px;
  }
}

.navbarTextContainer{
  color: $dark-gray-text !important;
  font-weight: 500 !important;

  .registerContainer{
    background-color: $first;
    border-radius: 10px;
    color: $white !important;
  }

  .authNavbarContainer {
    display: flex;
    flex-direction: row;
  }
}

.footer {
  background-color: $first;
  .footer-icons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
    svg {
      height: 30px;
    }
  }
}