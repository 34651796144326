@import "./constants/theme.scss";

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.btn-custom_primary {
  background-color: $first !important;
  color: $white !important;
  transition: .5s;

  &:hover {
    background-color: $fourth !important;
  }
}

.btn-custom_red {
  background-color: $third !important;
  color: $white !important;
  transition: .5s;

  &:hover {
    background-color: $third-hover !important;
  }
}

.btn-custom_second {
  background-color: $second !important;
  color: $white !important;
  transition: .5s;

  &:hover {
    background-color: $fifth !important;
  }
}

.btn-custom_third {
  background-color: $third !important;
  color: $white !important;
  transition: .5s;

  &:hover {
    background-color: $third-hover;
  }
}

a {
  color: $first !important;
}

.border-custom_primary {
  border: 2px solid $first !important;
}

.border-custom_third {
  border: 2px solid $third !important;
}

.border-custom_gray{
  border: 2px solid $gray !important;
}

.border-custom_light_gray{
  border: 2px solid $gray-border !important;
}
.border-custom_none{
  border: 0 !important;
}
.clickable {
  cursor: pointer;
}

.dropdown-menu{
  &.show{
    border: 1px solid $first;
  }
}

.card {
  position: relative;
}

.red-text { 
  color: $third;
}

.first-text {
  color: $first
}

.alertBannerContainer {
  width: 100%;
  padding: 20px;

  .action {
    color: $first;
    cursor: pointer;
  }

  &.alert_yellow{
    background-color: #ffcd43;
  }

  &.alert_orange{
    background-color: #e9a033;
  }
}

.errorCard {
  margin-bottom: 10px;
  min-height: 70px;

  .errorCardContainer{
    display: flex;
    flex-direction: row;

    .iconLeftCol{
      background-color: $third;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      svg{
        color: white;
        width: 50px;
      }
    }

    .rightContainer{
      border: 2px solid $third;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      width: 100%;

      .errorText{
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
      }
  
      .hint {
        font-size: 12px;
        font-style: italic;
      }
    }
  }
}

.dropdown-menu {
  &.show{
    z-index: 99999999;
  }
}

.center-spinner{
  display: flex;
  flex-direction: column;
}
